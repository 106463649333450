<template>
  <div class="firmDetails">
    <div class="box">
      <div class="back">
        <div class="btn" @click="$router.go(-1)">
          <i class="el-icon-arrow-left"></i>
          返回
        </div>
      </div>
      <div class="title">{{ firmInfo.title }}</div>

      <div class="date">{{ firmInfo.createTime }}</div>

      <div class="content" v-html="firmInfo.stepsContent"></div>
    </div>

    <div class="commonFooter_box">
      <commonFooter />
    </div>
  </div>
</template>

<script>
//例如：import  from '';
import commonFooter from "components/footer/commonFooter.vue";
import { getStepsContentById } from "api/header";
export default {
  name: "firmDetails",
  //import引入的组件需要注入到对象中才能使用
  components: {
    commonFooter,
  },
  data() {
    //这里存放数据
    return {
      firmInfo: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getContent(id) {
      const res = await getStepsContentById(id);

      if (res.code == 200) {
        this.firmInfo = res.data;
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getContent(this.$route.query.id);
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang="scss" scoped>
.firmDetails {
  position: absolute;
  background: #f5f5f2;
  top: 65px;
  left: 0;
  width: 100%;
  .box {
    width: 1450px;
    margin: 40px auto;
    padding: 40px;
    background-color: #fff;
    .back {
      .btn {
        display: flex;
        align-items: center;
        color: #ccc;
        cursor: pointer;
      }
    }
  }

  .title {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
  }

  .date {
    margin: 20px 0;
    text-align: center;
    font-size: 16px;
    color: #ccc;
  }

  .content {
    line-height: 1.8;
  }
}
</style>
